.waterfall {
    display: flex;
    width: 100%;
    flex: 1;
    height: 100%;
    justify-content: flex-start;
    padding: 1em;
}
.svg-content {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
}

.rect {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

.label {
    font-size: 12px;
    width: 60px;
    height: 30px;
    color: #1f4b6d;
    font-weight: bold;
}

.sublabel {
    font-size: 10px;
    color: #424242;
    font-weight: lighter;
}

.yLabel {
    width: 100px;
    height: 32px;
    font-size: 11px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    color: #222;
    text-align: right;
}

.positive {
    background: #05b2d9; /* Old browsers */
    background: -moz-linear-gradient(right, #05b2d9 0%, #034b6f 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(right, #05b2d9 0%, #034b6f 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to right,
        #05b2d9 0%,
        #034b6f 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#05b2d9', endColorstr='#034b6f',GradientType=1 ); /* IE6-9 */
}

.negative {
    background: #f6511d; /* Old browsers */
    background-image: linear-gradient(to left, #f6511d, #fb893e 0%);
    background: -moz-linear-gradient(left, #f6511d 0%, #fb893e 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #f6511d 0%, #fb893e 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to right,
        #fb893e 0%,
        #f6511d 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#feccb1', endColorstr='#fb955e',GradientType=1 ); /* IE6-9 */
}
